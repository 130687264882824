import { Splide } from '@splidejs/splide';

class ProductMediaSlider extends HTMLElement {
  constructor() {
    super();
    this.slides = this.innerHTML;
    this.splideOptions = {
      type: 'loop',
      perPage: 1,
      focus: 'center',
      arrows: true,
      pagination: false,
      easingFunc: 'cubic-bezier(0.25, 1, 0.5, 1)',
      arrowPath: 'M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z',
      classes: {
        arrows: 'splide__arrows',
        arrow: 'splide__arrow splide__arrow--lg !bg-transparent',
        prev: 'splide__arrow--prev',
        next: 'splide__arrow--next'
      }
    };
  }

  connectedCallback() {
    const slideElements = this.querySelectorAll('.splide__slide');
    if (slideElements.length > 1) {
      this.generateSlider();
    }
  }

  generateSlider() {
    this.splide = new Splide(this, this.splideOptions);
    this.splide.mount();
  }
}

if (!customElements.get('product-media-slider')) {
  customElements.define('product-media-slider', ProductMediaSlider);
}
